import * as firebase from 'firebase/app'
import firebaseCredentials from '../../../config/firebase'

const initFirebase = () => {
  return (
    (firebase.apps.length && firebase.apps[0]) ||
    firebase.initializeApp(firebaseCredentials)
  )
}

export default initFirebase
