import teal from '@material-ui/core/colors/teal'

export const primaryColor = { main: teal[700], ...teal }
export const secondaryColor = teal

export const primaryFont = 'Manjari'
export const secondaryFont = 'Source Sans Pro'
export const defaultFont = 'Roboto'

export const barSize = '60px'
export const leftSpacing = '32px'
export const pagePadding = '32px'
export const pageTitleBottomMargin = '8px'
export const rowGap = '64px'

export const pageTitleFontSize = '40px'
export const sectionTitleFontSize = '32px'
export const cardTitleFontSize = '24px'

export const cardWidth = '385px'
export const cardHeight = '140px'
export const cardRadius = '10px'
export const cardShadow = '0 4px 8px rgba(0, 0, 0, 0.25)'
export const cardGap = '16px'

export const cardRowHeight = '208px'
export const cardRowGap = '64px'

// Dark theme
export const darkThemeColorFirst = 'white'
export const darkThemeColorSecond = 'rgb(240, 244, 250)'
export const darkThemeColorThird = 'rgb(200, 204, 210)'

export const darkThemeBackgroundColorFirst = 'rgba(33, 35, 40, 1)'
export const darkThemeBackgroundColorSecond = 'rgba(45, 47, 52, 1)'
export const darkThemeBackgroundColorThird = 'rgba(64, 66, 71, 1)'
export const darkThemeBackgroundColorFourth = 'rgba(78, 78, 84, 1)'
