const firebaseCredentials = {
  apiKey: 'AIzaSyAHmgKjLJ1AKQkSMXltwr9cN3rG51WXW7o',
  authDomain: 'kosimst-smart-connect.firebaseapp.com',
  databaseURL: 'https://kosimst-smart-connect.firebaseio.com',
  projectId: 'kosimst-smart-connect',
  storageBucket: 'kosimst-smart-connect.appspot.com',
  messagingSenderId: '624287606768',
  appId: '1:624287606768:web:2ca8e1d451c640a7195f35',
  measurementId: 'G-R63QYVCX1B',
}

export const vapidKey =
  'BLkejWxRhnsFchA7oZZnj9IL_pTSgull9GYjqlsS79gjfcTI2SYDtC6Vd-6ESC5KqzilO2pl_nzcUC8tMyrE3EQ'

export default firebaseCredentials
