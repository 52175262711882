import { usePath } from 'hookrouter'

const useCategory = () => {
  const currentPath = usePath()

  const [, category, subcategory, id] = currentPath.split('/')

  return [category, subcategory, id]
}

export default useCategory
