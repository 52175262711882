import { useEffect, useState } from 'react'
import {
  createMuiTheme,
  Theme,
  SimplePaletteColorOptions,
  hexToRgb,
} from '@material-ui/core/styles'
import { Color } from '@material-ui/core'

interface ThemeProperties {
  [key: string]: string | SimplePaletteColorOptions | Partial<Color> | undefined
  primaryColor: SimplePaletteColorOptions | Partial<Color> | undefined
  secondaryColor?: SimplePaletteColorOptions | Partial<Color>
}

const useTheme = (theme: ThemeProperties) => {
  const [muiTheme, setMuiTheme] = useState<Theme | null>(null)

  useEffect(() => {
    Object.entries(theme).forEach(([key, value]) => {
      if (typeof value === 'object') {
        Object.entries(value).forEach(([variant, color]) => {
          const varName = `--${key.replace(
            /[A-Z]/g,
            letter => `-${letter.toLowerCase()}`,
          )}-${variant}`
          window.document.body.style.setProperty(varName, color)

          if (color.length === 7 && color[0] === '#') {
            const rgbVarName = `${varName}_rgb`
            const rgbValue = hexToRgb(color)
              .replace(/rgb\(/g, '')
              .replace(')', '')

            window.document.body.style.setProperty(rgbVarName, rgbValue)
          }
        })
      } else {
        const varValue = value || null
        const varName = `--${key.replace(
          /[A-Z]/g,
          letter => `-${letter.toLowerCase()}`,
        )}`
        window.document.body.style.setProperty(varName, varValue)
      }

      setMuiTheme(
        createMuiTheme({
          palette: {
            primary: theme.primaryColor,
            secondary: theme.secondaryColor,
          },
        }),
      )
    })
  }, [theme, setMuiTheme])

  return muiTheme
}

export default useTheme
