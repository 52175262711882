import React from 'react'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

import styles from './Landing.module.css'

import useUser from '../hooks/user'
import { useTitle } from '../hooks'

import cl from '../functions/classList'

const Landing: React.FC = () => {
  useTitle('Anmelden')

  const [user, loading, signIn] = useUser()

  return (
    <div
      className={cl([
        styles.Container,
        user && !user.pending && styles.FadeOut,
      ])}
    >
      <div
        className={cl([styles.Card, user && !user.pending && styles.FlyOut])}
      >
        {loading && <LinearProgress />}
        <h1 className={styles.Greeting}>Hallo!</h1>
        <h3 className={styles.Welcome}>Willkommen zu Hause.</h3>

        <Button
          className={styles.Button}
          color="primary"
          variant="contained"
          disabled={loading || (!!user && user.pending)}
          onClick={signIn}
        >
          {user && user.pending ? 'Ausgesperrt' : 'Aufsperren'}
        </Button>
      </div>
    </div>
  )
}

export default Landing
