import Room from '../types/Room'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import firestore from '../middlewares/firebase/modules/firestore'

const useCurrentRoomDetails = (roomId: string) => {
  const [raw] = useCollectionData(
    firestore
      .collection('/rooms')
      .where('id', '==', roomId)
      .limit(1),
  )

  const room = (raw && raw[0]) || null

  return room as Room | null
}

export default useCurrentRoomDetails
