import { useCollectionData } from 'react-firebase-hooks/firestore'
import roomsQuery from '../middlewares/firebase/queries/rooms'
import RoomOverview from '../types/RoomOverview'

const useRooms = (): [RoomOverview[] | null, boolean] => {
  const [snapshot, loading] = useCollectionData(roomsQuery())

  return [snapshot as RoomOverview[] | null, loading]
}

export default useRooms
