import { useTitle as useWindowTitle } from 'hookrouter'

import { APP_NAME } from '../config/properties'

const useTitle = (title: string) => {
  const windowTitle = `${title} - ${APP_NAME}`
  useWindowTitle(windowTitle)
}

export default useTitle
