import { useAuthState } from 'react-firebase-hooks/auth'
import { useDocument } from 'react-firebase-hooks/firestore'
import { useMemo, useEffect, useState } from 'react'
import auth from '../middlewares/firebase/modules/auth'
import firestore from '../middlewares/firebase/modules/firestore'
import firebase from '../middlewares/firebase/modules/firebase'
import User, { UserData } from '../types/User'

const useUser = (): [
  User | null,
  boolean,
  () => Promise<firebase.auth.UserCredential>,
  () => Promise<void>,
] => {
  const provider = useMemo(() => new firebase.auth.GoogleAuthProvider(), [])
  const [user, loading] = useAuthState(auth)

  const [userDoc, docLoading] = useDocument(
    user ? firestore.collection('/users').doc(user.uid) : null,
  )
  const [userData, setUserData] = useState<UserData | null>(null)

  useEffect(() => {
    if (userDoc && userDoc.exists) {
      const data = userDoc.data() as UserData
      setUserData(data)
    } else {
      setUserData(null)
    }
  }, [userDoc])

  const [creating, setCreating] = useState<boolean>(false)

  useEffect(() => {
    if (user && !creating && userDoc && !userDoc.exists) {
      const { uid, displayName: name, email: mail, photoURL: imageUrl } = user
      setCreating(true)
      firestore
        .collection('/users')
        .doc(uid)
        .set({
          name,
          imageUrl,
          mail,
          uid,
          pending: true,
        })
    }
  }, [user, userDoc, creating])

  const [manifestReady, setManifestReady] = useState(false)
  useEffect(() => {
    if (!user && manifestReady) return

    const updateManifest = async () => {
      const idToken = await firebase.auth().currentUser?.getIdToken()

      if (!idToken) return
      setManifestReady(true)

      const manifestUrl = `/api/generateManifest/${idToken}.json?no-cache=${Math.floor(
        Math.random() * 10000,
      )}`
      const linkElmnt = document.querySelector('link[rel="manifest"]')
      linkElmnt?.setAttribute('href', manifestUrl)
    }

    updateManifest()
  }, [user])

  const signIn = () => auth.signInWithPopup(provider)
  const signOut = () => auth.signOut()

  return [
    user && userData ? { ...user, ...userData } : null,
    loading || docLoading,
    signIn,
    signOut,
  ]
}

export default useUser
