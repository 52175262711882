import React, { Suspense, lazy, useRef, useEffect } from 'react'
import { ThemeProvider } from '@material-ui/styles'

import * as themeProperties from '../config/theme'

import Landing from './Landing'

import useUser from '../hooks/user'
import useTheme from '../hooks/theme'

const App: React.FC = () => {
  const [user] = useUser()
  const theme = useTheme(themeProperties)

  const Landed = user && !user.pending && lazy(() => import('./Landed'))

  const wrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const dark = false

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.setAttribute('theme', dark ? 'dark' : 'light')
    }
  }, [dark, wrapperRef.current])

  return (
    theme && (
      <ThemeProvider theme={theme}>
        <Suspense fallback={<span>Loading...</span>}>
          <div ref={wrapperRef}>{Landed ? <Landed /> : <Landing />}</div>
        </Suspense>
      </ThemeProvider>
    )
  )
}

export default App
